<template>
  <Layout  style="background-color: #e9ecef;max-height: 1000px">
    <PageHeader :title="title" />
    <hr style="margin-top: -1rem;height: 1px;"/>
    <div style="margin-top: -1rem;">
      <div class="row" style="height: auto">
        <div class="col-12">
          <form class="form-horizontal" role="form" @submit.prevent="blogForm" @submit="onblogFormSubmit" @reset="onblogFormReset">
            <b-form-group
                id="example text"
                label-for="text" >
              <b-form-input for="text" placeholder="请输入文章标题" style="width: 90%;float: left"
                            v-model="form.blogTitle"
                            :class="{ 'is-invalid': submitform && $v.form.blogTitle.$error }"></b-form-input>
              <div v-if="submitform && $v.form.blogTitle.$error" class="invalid-feedback">
                <span v-if="!$v.form.blogTitle.required">请输入文章标题</span>
              </div>
            </b-form-group>
          </form>
          <b-button type="primary" v-b-modal.modal-scrollable class="el-button--danger">发布文章</b-button>
        </div>
      </div>
      <div style="padding-top: 10px;" >
        <textarea name="editor1" v-model="form.blogContent"  id="editor1" rows="500" cols="80"></textarea>
      </div>
    </div>
    <!--新建博客弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-scrollable"
            @shown="openModal"
            scrollable no-close-on-backdrop
            title="发布文章"
            title-class="font-18" hide-footer @close="resetForm">
          <div class="col-12 ">
            <form class="form-horizontal" role="form" @submit.prevent="blogForm" @submit="onblogFormSubmit" @reset="onblogFormReset">
              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章分类" class="form_group">
                <select class="form-control" style="float: left;width:70%" v-model="form.typeId" :class="{ 'is-invalid': submitform && $v.form.typeId.$error }">
                  <option  v-for="type in typeList" :key="type.id" :value="type.id">{{type.typeName}}</option>
                </select>
                <div class="icon-demo-content">
                  <div class="col-lg-4" style="margin-top: 0px;cursor:pointer"><i v-b-modal.modal-catlog class="ri-add-line"></i></div>
                </div>
                <div v-if="submitform && $v.form.typeId.$error" class="invalid-feedback">
                  <span v-if="!$v.form.typeId.required">请选择分类</span>
                </div>
              </b-form-group>

              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章标签"  class="form_group">

                <div class="radio" style="min-height: 150px;width:100%;border:1px #eeeeee solid;max-height: 200px;overflow: auto" :class="{ 'is-invalid invalid-div': submitform && $v.form.tagIds.$error }">
                  <div style="float: left;margin-top: 5px">
                    <label class="radio-inline" style="padding: 10px 10px"
                           v-for="tag in tagList" :key="tag.id" >
                      <input type="checkbox" name="optradio" :value="tag.id" v-model="form.tagIds" :checked="form.tagIds.includes(tag.id)"> {{tag.tagName}}
                    </label>
                  </div>


                  <div class="icon-demo-content">
                    <div class="col-lg-4" style="margin-top: 5px;cursor:pointer"><i v-b-modal.modal-tag class="ri-add-line" ></i></div>
                  </div>
                </div>
                <div v-if="submitform && $v.form.tagIds.$error" class="invalid-feedback">
                  <span v-if="!$v.form.tagIds.required">请选择标签</span>
                </div>
              </b-form-group>

              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章类型" class="form_group">
                <select class="form-control" v-model="form.blogType" :class="{ 'is-invalid': submitform && $v.form.blogType.$error }">
                  <option selected value="1">原创</option>
                  <option value="2">转载</option>
                  <option value="3">翻译</option>
                </select>
                <div v-if="submitform && $v.form.blogType.$error" class="invalid-feedback">
                  <span v-if="!$v.form.blogType.required">请选择文章类型</span>
                </div>
              </b-form-group>
              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章封面" class="form_group">
                <div :class="{ 'is-invalid invalid-div  ': submitform && $v.form.titlePage.$error }">
                  <vue-dropzone
                      id="myVueDropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions" data-dz-remove v-model="form.titlePage" >
                    <div class="dropzone-custom-content">
                      <i class="ri-upload-cloud-2-line"></i>
                    </div>
                  </vue-dropzone>
                </div>
                <div v-if="submitform && $v.form.titlePage.$error" class="invalid-feedback">
                  <span v-if="!$v.form.titlePage.required">请上传文章封面</span>
                </div>
              </b-form-group>
              <div style="margin-top: 20px;float: right;padding: 20px 20px">
                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
    <!--新建分类弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-catlog"
            scrollable
            title="新建分类"
            title-class="font-18" hide-footer>
          <div class="col-12">
            <form class="form-horizontal" role="catlogForm" @submit.prevent="catlogFormValid" @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-for="text"
                  label-cols-lg="2"
                  label="分类名称" >
                <input
                    id="validationTooltip03"
                    v-model="catlogForm.typeName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': submitcatlogForm && $v.catlogForm.typeName.$error }"
                />
                <div v-if="submitcatlogForm && $v.catlogForm.typeName.$error" class="invalid-feedback">
                  <span v-if="!$v.catlogForm.typeName.required">请填写分类名称</span>
                </div>
              </b-form-group>
              <div style="margin-top: 20px;float: right;padding: 20px 20px">

                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
    <!--新建标签弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-tag"
            scrollable
            title="新建标签"
            title-class="font-18" hide-footer>
          <div class="col-12">
            <form class="form-horizontal" role="form" @submit.prevent="tagFormValid" @submit="onTagFormSubmit" @reset="onTagFormReset">
              <b-form-group
                  label-for="text"
                  label-cols-lg="2"
                  label="标签名称" >
                <input
                    id="validationTooltip"
                    v-model="tagForm.tagName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': submitTagForm && $v.tagForm.tagName.$error }"
                />
                <div v-if="submitTagForm && $v.tagForm.tagName.$error" class="invalid-feedback">
                  <span v-if="!$v.tagForm.tagName.required">请填写标签名称</span>
                </div>
              </b-form-group>
              <div style="margin-top: 20px;float: right;padding: 20px 20px">
                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
<script src="./.ckeditor/ckeditor.js">
</script>
<script>
import Layout from "./layouts/main";
import PageHeader from "@/components/page-header";
import vue2Dropzone from "vue2-dropzone";
import {required} from "vuelidate/lib/validators";


/**
 * Calendar component
 */
var titlePage;
export default {
  name: "blog",
  page: {
    title: "发布文章"
  },
  components: {vueDropzone: vue2Dropzone,Layout, PageHeader },
  data() {
    return {
      title: "发布文章",

      curCode:'',
      codeInput:false,
      cmOptions:{
        value:'',
        mode:"text/javascript",
        theme: "ambiance",
        readOnly: false,
      },

      dropzoneOptions: {
        // url: "http://localhost:8099/myblog/upload",
        url: 'https://memoryab.cn/myblog/upload',
        thumbnailWidth: 150,
        maxFiles:1,//一次性上传的文件数量上限
        maxFilesize: 20, //MB
        acceptedFiles: ".jpg,.gif,.png,.jpeg", //上传的类型
        addRemoveLinks: true,
        dictRemoveFile:'移除图片',
        headers: { },
        init:function(){
          this.on("addedfile", function(file) {
            // eslint-disable-next-line no-debugger
            console.log(file)
            //上传文件时触发的事件
          });
          this.on("removedfile",function(file){
            console.log(file)
            titlePage = ''
            //删除文件时触发的方法
          });
          this.on("success", function(file,res) {
            titlePage = res.data.files[0]
            console.log(titlePage)
          });
        }
      },
      submitform: false,
      submitcatlogForm:false,
      submitTagForm:false,

      form: {
        blogTitle: '',
        blogContent: '',
        titlePage: '',
        blogType: '',
        summary: '',
        quoteUrl: '',
        publishFlag: '',
        typeId:'',
        tagIds: [],
      },
      catlogForm: {
        id:'',
        typeName:'',
      },
      tagForm: {
        id:'',
        tagName:'',
      },
      typeList:[],
      tagList:[],

    }
  },
  methods: {

    tab (event) {

    },

    async onblogFormSubmit(evt) {
      this.form.titlePage = titlePage,
          evt.preventDefault()
      let url = '/blog/insert';
      if (this.form.id != '' && this.form.id != undefined) {
        url = '/blog/update';
      }
      this.form.blogContent = CKEDITOR.instances.editor1.getData();
      // eslint-disable-next-line no-debugger
      if (this.$v.form.$invalid) {
        return;
      } else {
        const {data: res} = await this.$blog.post(url,this.form )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          if (this.$v.form.id != '' && this.$v.form.id != undefined) {
            window.history.go(-1);
          }
          this.resetForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-scrollable");
          this.submitform = false;
          return res.data
        }

      }

    },
    resetForm(){
      if (this.form.id == null || this.form.id == '') {
        this.form = {
          blogTitle: '',
          blogContent: '',
          titlePage: '',
          blogType: '',
          summary: '',
          quoteUrl: '',
          publishFlag: '',
          typeId:'',
          tagIds: [],
        };
        CKEDITOR.instances.editor1.setData("");
      }

      this.$bvModal.hide("modal-scrollable");
      this.submitform = false;
    },
    onblogFormReset(evt) {
      evt.preventDefault()
      this.$bvModal.hide("modal-scrollable");
      this.submitform = false;
    },

    blogForm() {
      this.submitform = true;
      this.$v.form.$touch();
    },
    catlogFormValid(){
      this.submitcatlogForm = true;
      this.$v.catlogForm.$touch();
    },
    async onSubmit(evt) {
      evt.preventDefault()
      if (this.$v.catlogForm.$invalid) {
        return;
      } else {
        let url = '/type/insert';
        if (this.catlogForm.id != '') {
          url = '/type/update';
        }
        const {data: res} = await this.$blog.post(url,this.catlogForm )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          this.resetCatlogForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-catlog");
          this.submitcatlogForm = false;
          this.getTypeList();
          return res.data
        }

      }

    },
    onReset(evt) {
      console.log("close")
      evt.preventDefault()
      // Reset our form values
      this.resetCatlogForm();
      this.$bvModal.hide("modal-catlog");
      this.submitcatlogForm = false;
    },
    resetCatlogForm(){
      this.catlogForm = {
        typeName: '',
        id:'',
      }
    },

    tagFormValid(){
      this.submitTagForm = true;
      this.$v.$touch();
    },
    async onTagFormSubmit(evt) {
      evt.preventDefault()
      if (this.$v.tagForm.$invalid) {
        return;
      } else {
        let url = '/tag/insert';
        if (this.tagForm.id != '') {
          url = '/tag/update';
        }
        const {data: res} = await this.$blog.post(url,this.tagForm )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          this.resetTagForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-tag");
          this.submitTagForm = false;
          this.getTagList();
          return res.data
        }

      }
    },
    onTagFormReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.resetTagForm();
      this.$bvModal.hide("modal-tag");
      this.submitTagForm = false;
      console.log("close tag modal")
    },
    resetTagForm(){
      this.tagForm = {
        tagName: '',
        id:'',
      }
    },
    async getTypeList(){
      // 得到所有的分类
      const {data: res} = await this.$blog.get('/type/getall')
      this.typeList = res.data
    },
    async getTagList(){
      // 得到所有的分类
      const {data: res} = await this.$blog.get('/tag/getall')
      this.tagList = res.data
    },
    makeToast(variant = null,msg = null) {
      this.$bvToast.toast(msg, {
        title: variant,
        variant: variant,
        solid: true
      });
    },
    openModal(){
      this.getTagList();
      this.getTypeList();
      if (this.form.titlePage != null && this.form.titlePage != undefined && this.form.titlePage != '') {
        // eslint-disable-next-line no-debugger
        var file = {size:1,name: "Icon", type: "image/png" };
        var url = this.picServer + this.form.titlePage;
        titlePage = this.form.titlePage;
        console.log(this.form.titlePage)
        this.$nextTick(()=>{
          this.$refs.myVueDropzone.manuallyAddFile(file, url);
        })
      }
    }

  },
  validations: {
    form: {
      blogTitle: { required },
      titlePage: { required },
      blogType: { required },
      typeId: { required },
      tagIds: { required }
    },
    catlogForm: {
      typeName: { required },
    },
    tagForm: {
      tagName: { required },
    },

  },
  mounted() {
    document.getElementById("layout-header").hidden = true;
    document.getElementById("layout-footer").hidden = true;
    CKEDITOR.replace("editor1");

  },
  created() {
    // tab键向后缩进
    CKEDITOR.on('instanceReady', function (evt) {
      var editor = evt.editor;
      editor.on('key', function (evt) {
        if (evt.data.keyCode === 9) {
          console.log('Tab 键被按下');
          // 防止默认行为（阻止默认 Tab 键行为）
          evt.cancel();
          // 在光标所在位置插入四个空格
          var range = editor.getSelection().getRanges()[0];
          var space = new CKEDITOR.dom.text('\u00a0\u00a0\u00a0\u00a0');
          range.insertNode(space);
          range.selectNodeContents(space);
          editor.getSelection().selectRanges([range]);
          // 取消浏览器默认行为
          if (evt.data.preventDefault) {
            evt.data.preventDefault();
          } else {
            evt.data.returnValue = false;
          }
        }
      });
    });

    this.getTypeList();
    this.getTagList();
    if (typeof this.$route.query.blog !== 'undefined'){
      this.form = JSON.parse(this.$route.query.blog)
    }
    let tagList = this.form.tagList;
    let tagIds = [];
    if (tagList!= null && tagList != undefined) {
      tagList.map(tag=>{
        tagIds.push(tag.id);
      })
    }
    this.form.tagIds = tagIds;
    console.log(this.form);
  },

};
</script>


<style>
.el-button--danger {
  float: left;
  color: #FFF;
  background-color: #F56C6C;
  border-color: #F56C6C;
  border-radius: 10%;
  margin-left: 20px;;
}
.invalid-div{
  border: 1px solid red !important;
}
.icon-demo-content {
  color: #adb5bd;
  float: left;
}
.jodit-dialog__panel{
  min-width: 50%!important;
  width: 50%!important;
  max-width: 50%;
}
.jodit-dialog__content > div{
  min-width: 50%!important;
  width: 100%!important;
  max-width: 100%;
}
.post-content{
  min-width: 90%!important;
  width: 90%!important;
  max-width: 90%;
}

</style>